import styled from 'styled-components';
import React, { FC } from 'react';

import { PageProps } from 'gatsby';
import RenderAfterPageTransition from '../old/components/RenderAfterPageTransition';
import { breakpointUp } from '../styles/mediaUtils';

import Footer from '../old/components/Footer';
import SEO from '../new/components/SEO';

const ContentWrapper = styled.section`
  width: 100%;
  height: 100%;
`;

const StyledSection = styled.section`
  padding: 11rem 2rem 0;

  @media ${breakpointUp.xs} {
    padding-top: 16.5rem;
  }

  @media ${breakpointUp.md} {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  @media ${breakpointUp.lg} {
    padding-top: 19rem;
    max-width: 1376px;
    margin: 0 auto;
  }

  .mobileIvisible {
    display: none;

    @media ${breakpointUp.xs} {
      display: block;
    }
  }
`;

const StyledPageHeader = styled.div`
  width: 100%;
  line-height: 40px;
  letter-spacing: -0.29px;
  margin-bottom: 2rem;

  h2 {
    font-size: 22px;
  }

  @media ${breakpointUp.xs} {
    h2 {
      font-size: 32px;
    }

    letter-spacing: -0.37px;
    line-height: 48px;
  }

  @media ${breakpointUp.lg} {
    margin-bottom: 2.5rem;

    h2 {
      font-size: 32px;
    }
  }
`;

const StyledHeader = styled.div`
  margin: 3.25rem 0 2.5rem;

  h2 {
    font-size: 20px;
  }

  @media ${breakpointUp.xs} {
    margin-top: 4rem;

    h2 {
      font-size: 25px;
    }
  }

  @media ${breakpointUp.lg} {
    height: 45px;
    width: 100%;
    color: #000;
    letter-spacing: -0.29px;
    line-height: 45px;
    margin-top: 6rem;

    h2 {
      font-size: 25px;
    }
  }
`;

const StyledTextCol = styled.div`
  color: #53585f;
  font-size: 19px;
  height: auto;
  letter-spacing: -0.22px;
  line-height: 28px;

  a {
    text-decoration: underline;
    display: inline-block;
    margin-left: 3px;
    margin-right: 3px;
  }

  p {
    margin-bottom: 2.15rem;
  }

  @media ${breakpointUp.xs} {
    font-size: 20px;
    letter-spacing: -0.23px;
    line-height: 32px;

    p {
      margin-bottom: 2rem;
    }
  }

  @media ${breakpointUp.lg} {
    width: 850px;
    margin-bottom: -1.25rem;
  }
`;

const StyledList = styled.div`
  ul {
    color: turquoise;
    margin-left: -1.5rem;
    font-size: 15px;

    @media ${breakpointUp.lg} {
      margin-left: -1.3rem;
    }
  }

  li {
    line-height: 32px;
  }

  span {
    color: #52595f;
    font-size: 19px;
    display: block;
    padding-left: 0.3rem;
  }

  @media ${breakpointUp.xs} {
    ul {
      margin-bottom: 5.5rem;
    }

    span {
      display: block;
      padding-left: 0.3rem;
      line-height: 36.7px;
      letter-spacing: 0.4px;
    }
  }

  @media ${breakpointUp.lg} {
    ul {
      font-size: 18px;
      margin-bottom: -1rem;
      width: 850px;
    }

    span {
      font-size: 18px;
      padding-left: 0.2rem;
      display: block;
      line-height: 37px;
    }
  }
`;

const LastStyledTextCol = styled(StyledTextCol)`
  margin-bottom: 8rem;

  @media ${breakpointUp.lg} {
    margin-bottom: 9rem;
  }
`;

const ListTextCol = styled(StyledTextCol)`
  margin-bottom: -1rem;

  p {
    margin-bottom: 1.5rem;
  }

  .afterList {
    margin-top: 3rem;

    p {
      margin-bottom: 2.75rem;
    }
  }

  @media ${breakpointUp.xs} {
    margin-bottom: -1.2rem;

    p {
      margin-bottom: 1.2rem;
    }

    .afterList {
      margin-top: -2.5rem;

      p {
        margin-bottom: 1.75rem;
      }

      margin-bottom: 5.5rem;
    }
  }

  @media ${breakpointUp.lg} {
    margin-bottom: -1rem;

    p {
      margin-bottom: 1rem;
    }

    .afterList {
      margin-top: 3rem;
      margin-bottom: -1.65rem;

      p {
        margin-bottom: 2rem;
      }
    }
  }
`;

const PageIntroduce = styled(StyledTextCol)`
  @media ${breakpointUp.xs} {
    margin-bottom: 5.5rem;
  }

  @media ${breakpointUp.lg} {
    margin-bottom: -1.75rem;
  }
`;

const PrivacyPolicy: FC<PageProps> = () => {
  return (
    <>
      <SEO title="Privacy Policy" />
      <RenderAfterPageTransition>
        <ContentWrapper>
          <StyledSection>
            <StyledPageHeader>
              <h2>Privacy Policy</h2>
            </StyledPageHeader>
            <PageIntroduce>
              <p>
                Elsewhen respects and values the privacy of every person who
                visits or interacts with our websites and/or the websites of our
                clients (“Sites”); anyone who uses our applications and/or the
                applications we build for our clients (“Applications”); and
                anyone who otherwise make use of the goods and/or services that
                we provide (our “Services”).
              </p>
              <p>
                We are committed to ensuring transparency in terms of how we
                process your Personal Data, as detailed in our Privacy Policy
                and Cookie Policy.
              </p>
            </PageIntroduce>
            <StyledHeader>
              <h2>About this Policy</h2>
            </StyledHeader>
            <StyledTextCol>
              <p>
                Our Privacy Policy describes how we handle and protect your
                Personal Data in relation to our business activities and
                marketing, whether online on the Site, as part of client work,
                or offline - for example at events.
              </p>
              <p>
                This Privacy Policy explains our approach to any Personal Data
                we may collect or handle from you and the reasons for processing
                your Personal Data. This Privacy Policy also sets out your
                rights in respect of our processing of that Personal Data.
              </p>
              <p>
                Our Privacy Policy applies exclusively to the use of your
                Personal Data when obtained by us, it does not apply to your
                Personal Data collected from third parties.
              </p>
            </StyledTextCol>
            <StyledHeader>
              <h2>Who we are</h2>
            </StyledHeader>
            <StyledTextCol>
              <p>
                Our Sites and Applications are operated by Elsewhen Limited,
                (“we”, “us” or “our”).
              </p>
              <p>
                Elsewhen Limited is the data controller responsible for your
                personal information and is registered at the UK Information
                Commissioner’s Office with registration number: 07608360
              </p>
              <p>
                Elsewhen Limited is a UK company with registered company number:
                07608360 and whose registered office is at Unit 13 Waterside,
                44-48 Wharf Road, London N1 7UX
              </p>
            </StyledTextCol>
            <StyledHeader>
              <h2>Contact us</h2>
            </StyledHeader>
            <StyledTextCol>
              <p>
                If you have questions about our Privacy Policy or want to
                exercise your rights as defined by it, please contact us by
                sending an email to hello@elsewhen.com with the subject line
                ‘Elsewhen Privacy Policy’. We will endeavour to respond to all
                queries in good time.
              </p>
            </StyledTextCol>
            <StyledHeader>
              <h2>Data we collect and how we use it</h2>
            </StyledHeader>
            <ListTextCol>
              <p>
                Elsewhen may collect data in the normal function of our Sites,
                Applications or Services. The purpose of this data collection
                can be summarised thusly:
              </p>
              <StyledList>
                <ul>
                  <li>
                    <span>Provision of Services to Clients</span>
                  </li>
                  <li>
                    <span>Managing communication with Clients</span>
                  </li>
                  <li>
                    <span>Improving our Services</span>
                  </li>
                  <li>
                    <span>Promoting and marketing our Services</span>
                  </li>
                  <li>
                    <span>Handling inquiries we receive</span>
                  </li>
                  <li>
                    <span>Analysing how our Sites are being used</span>
                  </li>
                  <li>
                    <span>Recruiting new personnel</span>
                  </li>
                  <li>
                    <span>
                      In order to comply with any applicable law, court order,
                      other judicial process, or the requirements of a
                      regulator; and use as otherwise required or permitted by
                      law
                    </span>
                  </li>
                </ul>
              </StyledList>
            </ListTextCol>
            <StyledHeader>
              <h2>Legal basis by which we process your personal data</h2>
            </StyledHeader>
            <ListTextCol>
              <p>
                Our processing of your personal data for the purposes mentioned
                above is based:
              </p>
              <StyledList>
                <ul>
                  <li>
                    <span>
                      On our legitimate interests in promoting and protecting
                      our Sites, Applications and Services, building and
                      maintaining correspondence, and for the purposes of
                      recruitment
                    </span>
                  </li>
                  <li>
                    <span>
                      On your consent, for example if you register for a
                      Service, register for the email newsletter, register at
                      any event we operate, or for the business purposes that
                      support web operations such as on our site
                    </span>
                  </li>
                  <li>
                    <span>
                      To comply with laws, when it may be necessary to satisfy
                      our legal or regulatory obligations
                    </span>
                  </li>
                </ul>
              </StyledList>
            </ListTextCol>
            <StyledHeader>
              <h2>Retention of your data</h2>
            </StyledHeader>
            <StyledTextCol>
              <p>
                We reserve the right to retain personal data, for the duration
                of the relevant business engagement, or longer is necessary. In
                most cases we will delete all personal data collected as part of
                a business engagement, but it may be necessary to retain this
                data for longer, for example for analysis or record-keeping, to
                comply with schedules agreed with a client, to comply with any
                legal or regulatory obligations, or to protect us against future
                legal action.
              </p>
              <p>
                If you request that we delete your personal data, we will make
                reasonable attempts to delete all instances of the information
                in their entirety. More information on access to your personal
                data, and your right to be forgotten is included in the “Your
                Rights” section of this Policy.
              </p>
            </StyledTextCol>
            <StyledHeader>
              <h2>Your rights</h2>
            </StyledHeader>
            <ListTextCol>
              <p>You may have the right to:</p>
              <StyledList>
                <ul>
                  <li>
                    <span>
                      request access to your Personal Data as collected by
                      Elsewhen, where it has been granted by applicable law
                    </span>
                  </li>
                  <li>
                    <span>
                      request a copy of the Personal Data as collected by
                      Elsewhen , where it has been granted by applicable law
                    </span>
                  </li>
                  <li>
                    <span>
                      request to have any inaccuracies in your Personal Data
                      corrected
                    </span>
                  </li>
                  <li>
                    <span>
                      request that we cease processing your personal data, where
                      it has been granted by applicable law
                    </span>
                  </li>
                </ul>
              </StyledList>
              <StyledTextCol className="afterList">
                <p>
                  If you wish to do any of the above, or discuss how we process
                  your Personal Data, you can contact
                  <a href="mailto:hello@elsewhen.com">hello@elsewhen.com</a>
                  with the subject line “Elsewhen Privacy Policy” and we will
                  endeavour to respond to your request in good time, and make
                  every reasonable attempt to comply with your request as
                  required by applicable law
                </p>
                <p>
                  To help protect your privacy and security, we will take
                  reasonable steps to verify your identity before granting you
                  access to your Personal Data.
                </p>
                <p>
                  It may be legally impossible to comply with your request, for
                  example if access to your Personal Data may reveal the
                  Personal Data of someone else, for whose Personal Data you do
                  not have consent. It may be necessary to deny your requests
                  where they may be manifestly unfounded, and/or excessive, or
                  otherwise objectionable or unwarranted under applicable law.
                  It may be necessary to charge a fee to comply with your
                  request where granted by applicable law.
                </p>
                <p>
                  You have the legal right to lodge a complaint with a competent
                  data protection authority, where granted by applicable law.
                </p>
              </StyledTextCol>
            </ListTextCol>
            <StyledHeader className="mobileIvisible">
              <h2>Unsubscribing</h2>
            </StyledHeader>
            <StyledTextCol className="mobileIvisible">
              <p>
                You can opt in or out from tracking cookies on our
                <a href="/cookie-policy/">Cookie Policy.</a>
              </p>
              <p>
                You can unsubscribe from our email newsletters by clicking the
                ‘Unsubscribe’ link at the bottom of the email.
              </p>
            </StyledTextCol>
            <StyledHeader className="mobileIvisible">
              <h2>Consent and changes to Privacy Policy</h2>
            </StyledHeader>
            <LastStyledTextCol className="mobileIvisible">
              <p>
                By using the Sites, you consent to the collection, use, and
                storage of your personal data by us in the manner described in
                this Privacy Policy and in our Cookie Policy. We reserve the
                right to make changes to this Privacy Policy as necessary.
              </p>
              <p>
                If we make changes to this Privacy Policy that meaningfully
                increase our right to use personal data that we have previously
                collected, we will obtain your consent by prominently posting
                information about the change on our Sites, or via email to you,
                depending on the nature of the change.
              </p>
            </LastStyledTextCol>
          </StyledSection>
          <Footer />
        </ContentWrapper>
      </RenderAfterPageTransition>
    </>
  );
};

export default PrivacyPolicy;
