import { useCallback, useContext, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import IsPageTransitioning from '../old/components/RoutesTransitionGroup/context';

export type LazyLoadProps = {
  ref: (node?: Element | null | undefined) => void;
  readyToLoad: boolean;
};

export const useLazyLoad = (
  waitForPage?: boolean,
  rootMargin?: string,
  threshold?: number
): LazyLoadProps => {
  const isPageTransitioning = useContext(IsPageTransitioning);
  const [enteredViewport, setEnteredViewport] = useState(false);
  const readyToLoad = enteredViewport && (!waitForPage || !isPageTransitioning);

  const handleObserverEvent = useCallback(
    (inView: boolean) => {
      if (inView) {
        setEnteredViewport(true);
      }
    },
    [setEnteredViewport]
  );

  const { ref } = useInView({
    triggerOnce: true,
    threshold: threshold ?? 0.001,
    rootMargin: rootMargin || '0px 0px 5px 0px',
    onChange: handleObserverEvent,
  });

  return { ref, readyToLoad };
};
